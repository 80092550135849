import React, { useEffect } from 'react';

import SocialNetworkFreePage from '@components/social-network-free-page/social-network-free-page';
import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import couponCodeService from '@src/utils/couponCodeService';

const FacebookFree = () => {
  const { landing, home } = useFacebookLandingText();
  const { setOffer } = couponCodeService();
  const text = {
    ...landing,
    ...home,
    hero_text: landing.hero_description,
    hero_focus: landing.hero_focus_text,
    hero_focus_price: landing.hero_focus_price,
    how_it_work_header: landing.how_it_work_title,
    how_it_works_items_list: landing.how_it_works_items,
  };

  useEffect(() => {
    setOffer('4pp', 'freeTrial');
  }, []);

  return <SocialNetworkFreePage text={text} noindex />;
};

export default FacebookFree;
